import wailalogo from "../../Assets/Images/Waila marketplace.svg";
import products from "../../Assets/Images/product's.png";
import introImage from "../../Assets/Images/intro.jpg";
import videoContent from "../../Assets/Videos/video.mp4";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

export const WorkSpaceJson = {
  header: {
    Logo: wailalogo,
    MenuList: [
      {
        id: 1,
        title: "Solutions",
        subItems: [
          {
            title: "For Individuals",
            subItems: [
              {
                title: "Overview",
                subTitle: "Google Workspace Individual",
                logo: "",
              },
            ],
          },
          {
            title: "For Business",
            subItems: [
              {
                title: "Overview",
                subTitle: "Google Workspace Individual",
                logo: "",
              },
              {
                title: "Small business",
                subTitle: "Small business productivity tools",
                logo: "",
              },
              {
                title: "New business",
                subTitle: "Tools for new businesses",
                logo: "",
              },
              {
                title: "Start-ups",
                subTitle: "Start-up productivity tools",
                logo: "",
              },
            ],
          },
          {
            title: "For Enterprice",
            subItems: [
              {
                title: "Duet AI in Workspace",
                subTitle: "Unlock new ways of working",
                logo: "",
              },
              {
                title: "Frontline workers",
                subTitle: "Google Workspace for the frontline",
                logo: "",
              },
              {
                title: "Work safer",
                subTitle: "Protect organisations from cyberattacks",
                logo: "",
              },
            ],
          },
        ],
        subNavigators: [
          {
            title: "Developers"
          },
          {
            title: "Education"
          },
          {
            title: "Nonprofits"
          }
        ]
      },
      {
        id: 2,
        title: "Products",
        subItems: [
          {
            title: "Our Products List",
            subItems: [
              {
                title: "Tymeplus",
                subTitle: "HR management Tool",
                logo: "",
              },
              {
                title: "Track Task",
                subTitle: "Task Management and Project Management",
                logo: "",
              },
              {
                title: "Field Sale",
                subTitle: "Sales Management",
                logo: "",
              },
            ],
          },
        ],
        subNavigators: [
    
        ]
      },
    ],
    ActionButtonList: [
      {
        name: "Contact Sales",
        type: "outlined"
      },
      {
        name: "Start Free Trail",
        type: "filled"
      }
    ]
  },

  banner: "Productivity and collaboration tools for all the ways that we work.",

  introduction: {
    ProductsIcon: products,
    Details: {
      heading: "How teams of all sizes connect, create and collaborate.",
      summary: "A flexible, innovative solution for people and organisations to achieve more.",
      buttonName: "Start Free Trial"
    },
    Svg: introImage,
    Video: videoContent,
    VideoHeading: "Productivity and collaboration tools for all the ways that we work.",
  },

  Card: {
    tag: "ALL THE LATEST",
    title: "Learn more about the future of work.",
    summary: "Stay up to date with the latest stories, events and more with Google Workspace.",
    cards: [
      {
        image: introImage,
        title: "Unlock new ways of working with Duet AI in Workspace.",
        tag: "ANNOUNCEMENT",
        ButtonName: "Learn more"
      },
      {
        image: introImage,
        title: "Discover new insights and stories about the future of work.",
        tag: "TRANSFORMATION",
        ButtonName: "Learn more"
      },
      {
        image: products,
        title: "Stay up to date with the latest stories and product news on the Google Workspace blog.",
        tag: "News",
        ButtonName: "Learn more"
      },
      {
        image: introImage,
        title: "5 Reasons Google Workspace Enables Anytime, Anywhere Collaboration",
        tag: "guide",
        ButtonName: "Learn more"
      },
      {
        image: introImage,
        title: "Champions of Change - Celebrating the heroes behind workplace transformation",
        tag: "Videos",
        ButtonName: "Learn more"
      }
    ]
  },

  questions: {
    title: "Find the answers that you need",
    list: [
      {
        id: 1,
        question: "What’s included with Google Workspace (formerly G Suite)?",
        answer: "Similar to G Suite, all Google Workspace plans provide a custom email for your business and include collaboration tools like Gmail, Calendar, Meet, Chat, Drive, Docs, Sheets, Slides, Forms, Sites and more. For additional details, visit our plans and pricing page."
      },
      {
        id: 1,
        question: "What’s included with Google Workspace (formerly G Suite)?",
        answer: "Similar to G Suite, all Google Workspace plans provide a custom email for your business and include collaboration tools like Gmail, Calendar, Meet, Chat, Drive, Docs, Sheets, Slides, Forms, Sites and more. For additional details, visit our plans and pricing page."
      },
      {
        id: 1,
        question: "What’s included with Google Workspace (formerly G Suite)?",
        answer: "Similar to G Suite, all Google Workspace plans provide a custom email for your business and include collaboration tools like Gmail, Calendar, Meet, Chat, Drive, Docs, Sheets, Slides, Forms, Sites and more. For additional details, visit our plans and pricing page."
      },  {
        id: 1,
        question: "What’s included with Google Workspace (formerly G Suite)?",
        answer: "Similar to G Suite, all Google Workspace plans provide a custom email for your business and include collaboration tools like Gmail, Calendar, Meet, Chat, Drive, Docs, Sheets, Slides, Forms, Sites and more. For additional details, visit our plans and pricing page."
      },
      {
        id: 1,
        question: "What’s included with Google Workspace (formerly G Suite)?",
        answer: "Similar to G Suite, all Google Workspace plans provide a custom email for your business and include collaboration tools like Gmail, Calendar, Meet, Chat, Drive, Docs, Sheets, Slides, Forms, Sites and more. For additional details, visit our plans and pricing page."
      }
    ],
    containerList : [
      {
        id: 1,
        title: "Explore Learning Centre",
        summary: "Browse tips, guides and how-to videos made for both new users and power users.",
        buttonName: "See resources"
      },
      {
        id: 2,
        title: "Get admin help",
        summary: "Sign in to find specific answers, manage users or get support as a developer.",
        buttonName: "Get support"
      }
    ]
  },

  trailInfo : {
    title: "Show the world how it's done.",
    buttonName: "Start Free Trial"
  },

  footer : {
     socialMediaQoutes : "Follow our Blog",
     socialMediaIcons : [
      {
        url:"",
        icon : <FacebookIcon fontSize="40px"/>,
      },
      {
        url:"",
        icon : <TwitterIcon fontSize="40px"/>,
      },
      {
        url:"",
        icon : <YouTubeIcon fontSize="40px"/>,
      },
      {
        url:"",
        icon : <InstagramIcon fontSize="40px"/>,
      }
     ],
     listSites : [
      {
        title: "Included applications",
        subItems: [
          {
            name:"Gmail"
          },
          {
            name:"Meet"
          },
          {
            name:"Chat"
          },
          {
            name:"Calendar"
          },
          {
            name:"Docs"
          },
          {
            name:"Sheet"
          },
          {
            name:"Sites"
          },
          {
            name:"Forms"
          }
        ]
      },
      {
        title: "Security and management",
        subItems: [
          {
            name:"Admin"
          },
          {
            name:"Endpoint"
          },
          {
            name:"Vault"
          },
          {
            name:"Work Insights"
          },
        ]
      },
      {
        title: "Solutions",
        subItems: [
          {
            name:"New business"
          },
          {
            name:"Small business"
          },
          {
            name:"Enterprise"
          },
          {
            name:"Artificial Intelligence"
          },
        ]
      },
      {
        title: "Pricing",
        subItems: [
          {
            name:"Choose an edition"
          },
        ]
      },
      {
        title: "Add-ons",
        subItems: [
          {
            name:"Duet AI"
          },
          {
            name:"Meet hardware"
          },
          {
            name:"AppSheet"
          },
          {
            name:"Artificial Intelligence"
          },
        ]
      },
      {
        title: "Resources",
        subItems: [
          {
            name:"Working remotely"
          },
          {
            name:"Security"
          },
          {
            name:"AppSheet"
          },
          {
            name:"Artificial Intelligence"
          },
        ]
      },
      {
        title: "Resources",
        subItems: [
          {
            name:"Working remotely"
          },
          {
            name:"Security"
          },
          {
            name:"AppSheet"
          },
          {
            name:"Artificial Intelligence"
          },
          {
            name:"Working remotely"
          },
          {
            name:"Security"
          },
          {
            name:"AppSheet"
          },
          {
            name:"Artificial Intelligence"
          },
        ]
      },
      {
        title: "Learning and support",
        subItems: [
          {
            name:"Learning and support"
          },
          {
            name:"Security"
          },
          {
            name:"AppSheet"
          },
          {
            name:"Artificial Intelligence"
          },
          {
            name:"Working remotely"
          },
          {
            name:"Security"
          },
          {
            name:"AppSheet"
          },
          {
            name:"Artificial Intelligence"
          },
        ]
      },
     ],

    siteTitle: "Waila Marketplace" 
  }
};
